import { Link, useSearchParams } from '@remix-run/react';
import { Navigate, useNavigate } from '@remix-run/react';

import { EnumsPageName } from '@lp-lib/api-service-client/public';
import { type BlockType } from '@lp-lib/game';

import {
  BlockUniverseCard,
  BrandDiscovery,
  SearchBrandList,
  TaggedBrandList,
  UntaggedBrandList,
  useBrandsCount,
} from '../components/Brand';
import { useTagBySlug } from '../components/Game/GameCenter';
import { Loading } from '../components/Loading';
import { CreateBlockButton } from '../components/RoutedBlock/CreateBlockButton';
import { EditCategoriesButton } from '../components/Tagging';
import { AdminView } from '../pages/Admin/AdminView';
import { apiService } from '../services/api-service';

function BreadCrumbs(props: { children: React.ReactNode }) {
  return (
    <div className='flex items-center gap-2'>
      <Link
        to='/admin/brands'
        className='text-xl text-secondary hover:text-primary hover:underline'
      >
        Brands
      </Link>
      <span className='text-white text-3xl'>/</span>
      <span className='text-white text-xl'>{props.children}</span>
    </div>
  );
}

function AdminBrandLibrary() {
  const [searchParams] = useSearchParams();
  const tagSlug = searchParams.get('tag');
  const untagged = Boolean(searchParams.get('untagged'));
  const q = searchParams.get('q');
  const brandId = searchParams.get('brandId');
  const pageSize = Number(searchParams.get('pageSize')) || 96;
  const gridTemplateColumns =
    Number(searchParams.get('gridTemplateColumns')) || 3;
  const { data: brandsCount } = useBrandsCount();

  const { data: tag } = useTagBySlug(tagSlug);
  const navigate = useNavigate();

  if (brandId) {
    return <Navigate to={`/admin/brands/${brandId}`} replace />;
  }

  if (q) {
    return (
      <div className='w-full h-full text-white px-12.5 py-20 flex flex-col gap-10'>
        <BreadCrumbs>Search Results: {q}</BreadCrumbs>

        <SearchBrandList
          query={q}
          pageSize={pageSize}
          gridTemplateColumns={gridTemplateColumns}
          editable
        />
      </div>
    );
  }

  if (untagged) {
    return (
      <div className='w-full h-full text-white px-12.5 py-20 flex flex-col gap-10'>
        <BreadCrumbs>Untagged</BreadCrumbs>
        <UntaggedBrandList
          pageSize={pageSize}
          gridTemplateColumns={gridTemplateColumns}
          editable
        />
      </div>
    );
  }

  if (tagSlug) {
    if (!tag) return <Loading />;
    return (
      <div className='w-full h-full text-white px-12.5 py-20 flex flex-col gap-10'>
        <BreadCrumbs>{tag.name}</BreadCrumbs>
        <TaggedBrandList
          tagId={tag.id}
          pageSize={pageSize}
          gridTemplateColumns={gridTemplateColumns}
          editable
        />
      </div>
    );
  }

  const handleAddBlock = async (type: BlockType) => {
    const resp = await apiService.block.createStandaloneBlock({ type });
    const block = resp.data.block;
    navigate(`/admin/blocks/${block.id}`);
  };

  return (
    <div className='w-full h-full text-white px-12.5 py-20 flex flex-col gap-10'>
      <header className='flex justify-between items-center'>
        <h1 className='text-3.5xl font-bold'>
          Brands & Blocks Library {brandsCount && `(${brandsCount})`}
        </h1>

        <div className='flex items-center gap-5'>
          <Link
            to={'/admin/brands/create'}
            className='btn-secondary w-37.5 h-10 flex justify-center items-center'
          >
            Create Brand
          </Link>
          <CreateBlockButton align='right' onAddBlock={handleAddBlock} />
        </div>
      </header>

      <div className='flex justify-between items-center'>
        <BlockUniverseCard onClick={() => navigate('/admin/blocks')} />

        <EditCategoriesButton context={EnumsPageName.PageNameBrands} />
      </div>

      <BrandDiscovery
        pageSize={pageSize}
        gridTemplateColumns={gridTemplateColumns}
        editable
      />
    </div>
  );
}

export function Component() {
  return (
    <AdminView
      search={{
        targetPathname: '.',
        placeholder: 'Search Brands',
      }}
      className='bg-library-2023-07'
    >
      <AdminBrandLibrary />
    </AdminView>
  );
}
