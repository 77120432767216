import { useState } from 'react';

import { type BlockType } from '@lp-lib/game';

import { ArrowDownIcon, ArrowUpIcon } from '../icons/Arrows';
import { BlockTypeChooser } from './BlockTypeChooser';

export function CreateBlockButton(props: {
  onAddBlock: (type: BlockType) => void;
  align?: 'left' | 'right';
}) {
  const [open, setOpen] = useState(false);
  const align = props.align ?? 'left';
  return (
    <div className='relative flex-none'>
      <button
        type='button'
        className='btn-primary h-10 px-4 flex items-center justify-center gap-1 font-medium'
        onClick={() => setOpen(!open)}
      >
        <div>Create Block</div>
        {open ? (
          <ArrowUpIcon className='w-4 h-4 fill-current' />
        ) : (
          <ArrowDownIcon className='w-4 h-4 fill-current' />
        )}
      </button>
      {open && (
        <div
          className={`z-5 absolute mt-1 ${
            align === 'left' ? 'left-0' : 'right-0'
          }`}
        >
          <BlockTypeChooser
            onAddBlock={(type) => {
              props.onAddBlock(type);
              setOpen(false);
            }}
            onCancel={() => setOpen(false)}
          />
        </div>
      )}
    </div>
  );
}
